import { Button, Checkbox, Link, Ricon, Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useResponsive, useSelectionHook } from '../hooks';
import { ICreditVoucher } from '../types/creditVoucher';
import { useAvoidExit } from '../hooks/useAvoidExit';
import useTransactionImagoEvent from '../hooks/useTransactionImagoEvent';
import { Trans, useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL, REFUND_POLICY, TERMS_URL } from '../constants/linkURL';
import { Layout } from '../components';

export interface CheckPaymentAgreementContentsProps {
  onBack: useSelectionHook['onBackClick'];
  onSubmit: SubmitHandler<{ agreement: boolean }>;
  selectedCredit: ICreditVoucher;
  onClose: useSelectionHook['onClose'];
}

const SELECTED_ITEM_INFO = [
  {
    text: 'Selected credits',
    color: 'text.secondary',
    isBottomDivider: true,
    i18n: 'dialog_buy_credits.subtitle_selected_credits',
  },
  {
    text: 'Total credits',
    color: '',
    isBottomDivider: false,
    i18n: 'dialog_buy_credits.subtitle_total_credits',
  },
];

export const CheckPaymentAgreementContent = ({
  selectedCredit,
  onBack,
  onSubmit,
  onClose,
}: CheckPaymentAgreementContentsProps) => {
  useAvoidExit();
  const { responsiveCSS } = useResponsive();
  const { t } = useTranslation();
  const { handleChargeCreditEvent } = useTransactionImagoEvent();
  const thisYear = new Date().getFullYear();

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm({ defaultValues: { agreement: false } });

  return (
    <Layout>
      <Stack
        sx={{ mt: responsiveCSS('28px', '6px'), mb: responsiveCSS('16px', '30px') }}
        flexDirection={'row'}
        gap={responsiveCSS('8px', '0px')}
        alignItems={'center'}
      >
        <Box
          id="contentBackBtn"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: responsiveCSS('48px', '44px'),
            height: responsiveCSS('48px', '44px'),
          }}
          onClick={onBack}
        >
          <Ricon
            icon="ri-arrow-left-line"
            svgProps={{
              width: 24,
              height: 24,
            }}
          />
        </Box>
        <Typography variant={responsiveCSS('H24', 'Subtitle_Semibold18')}>
          {t('dialog_buy_credits.title', { count: selectedCredit.creditAmount })}
        </Typography>
      </Stack>

      <Stack
        sx={{ gap: '16px', bgcolor: 'grey.100', p: '20px', borderRadius: '8px', mb: responsiveCSS('28px', '24px') }}
      >
        {SELECTED_ITEM_INFO.map((selectedItem) => (
          <Box key={selectedItem.text}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ mb: selectedItem.isBottomDivider ? '16px' : '' }}
            >
              <Typography variant={responsiveCSS('Body18', 'Body16')} color={selectedItem.color}>
                {t(selectedItem.i18n)}
              </Typography>
              <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                <Typography
                  variant={responsiveCSS('Subtitle_Semibold18', 'Subtitle_Semibold16')}
                  color={selectedItem.color}
                >
                  {selectedCredit.creditAmount}
                </Typography>
                <Typography variant={responsiveCSS('Body16', 'Body14')} color={selectedItem.color}>
                  {t('dialog_buy_credits.this_is_an_unsigned')}
                </Typography>
              </Stack>
            </Stack>
            {selectedItem.isBottomDivider && <Box sx={{ borderTop: (t) => `1px solid ${t.palette.divider}` }} />}
          </Box>
        ))}
      </Stack>

      <Stack
        sx={{
          margin: '20px 0 24px',
          width: '100%',
          p: '20px',
          borderRadius: '8px',
          bgcolor: (t) => t.palette.blue[10],
        }}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="H18">{t('dialog_buy_credits.subtitle_amount_due')}</Typography>
        <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
          <Typography variant="Subtitle_Semibold18">$ {selectedCredit.voucherPrice.toLocaleString()}</Typography>
          <Typography variant="Body16">USD</Typography>
        </Stack>
      </Stack>

      <Controller
        control={control}
        name={'agreement'}
        rules={{ required: true, validate: (value) => value === true }}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            variant="basic"
            textVerticalAlign="top"
            sx={{ pl: 0 }}
            label={
              <Typography variant={responsiveCSS('Body16', 'Body14')} color={'text.secondary'}>
                <Trans
                  i18nKey="dialog_buy_credits.terms_of_service"
                  components={{
                    u1: <Link href={TERMS_URL} target="_blank" />,
                    u2: <Link href={PRIVACY_POLICY_URL} target="_blank" />,
                    u3: <Link href={REFUND_POLICY} target="_blank" />,
                  }}
                />
              </Typography>
            }
            value={value}
            onChange={(e) => {
              onChange(e);
              handleChargeCreditEvent({
                chargeCreditStep: 'termsAgree',
              });
            }}
          />
        )}
      />
      <Stack gap={responsiveCSS('4px', '10px')} sx={{ mt: '16px', mb: responsiveCSS('28px', '24px') }}>
        <Typography variant={'Subtitle_Semibold12'} color={'text.secondary'}>
          ⓒ {thisYear} Imagoworks Inc.
        </Typography>
        <Typography variant={'Caption11'} color={'text.secondary'}>
          1F, 2F, 636, Gangnam-daero, Gangnam-gu, Seoul, Republic of Korea (ZIP 06034)
        </Typography>
      </Stack>

      <Button
        id="continueToPaymentBtn"
        variant="contained"
        size={responsiveCSS('60', '48')}
        color="blue"
        sx={{ width: '100%', minWidth: '120px' }}
        type={'submit'}
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
      >
        {t('dialog_buy_credits.btn_continue_to_payment')}
      </Button>
      <Button
        id="agreementPageCancelCreditBtn"
        variant="outlined"
        size={responsiveCSS('60', '48')}
        color="grey"
        sx={{ width: '100%', mt: '16px' }}
        type={'submit'}
        onClick={onClose}
      >
        {t('dialog_buy_credits.btn_cancel_purchase_credit')}
      </Button>
    </Layout>
  );
};

export default CheckPaymentAgreementContent;
