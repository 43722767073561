import axios, { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { NETWORK_DISCONNECTION_CODE } from './constants/errorCode';
import { useNavigate } from 'react-router-dom';
import { NETWORK_ERROR_PAGE_URL } from './constants/pageUrls';
import { useAuthContext } from './auth/useAuthContext';
import ImagoEvent from '@imago-cloud/action-log';
import { isImagoworks } from './util/general';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 0,
});

const AxiosInterceptor = ({ children }: { children: React.ReactElement | null }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const errorInterceptor = async (error: any) => {
      const errorCode = error?.response?.data?.errorCode;

      if ((error as AxiosError).code === NETWORK_DISCONNECTION_CODE) {
        const {
          userInfo: { tenantId, userId },
          emailFullName: { email },
        } = useAuthContext();
        ImagoEvent.error.errorPageAppeared({
          userId,
          isImagoworks: isImagoworks(email),
          tenantId,
          ErrorPage: window.location.pathname,
          ErrorCode: errorCode,
          ErrorMessage: 'Network Error',
        });
        navigate(NETWORK_ERROR_PAGE_URL); // 아니면 여기가서 retry를 해!
      }

      if (window.opener && document.referrer) {
        window.opener.postMessage({ success: false, data: error.response.data }, new URL(document.referrer).origin);
      }

      return Promise.reject(error.response.data);
    };

    const interceptor = instance.interceptors.response.use((res) => res, errorInterceptor);

    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, []);

  return children;
};

export default instance;
export { AxiosInterceptor };
