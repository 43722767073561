export const ROOT_PAGE_URL = '/';
export const SUCCESS_WAIT_PAGE_URL = '/success-wait';
export const ACCESS_DENIED_PAGE_URL = '/access-denied';
export const NETWORK_ERROR_PAGE_URL = '/network-error';
export const PAYMENT_WALL_PAGE_URL = '/payment-wall';
export const PAYMENT_SUCCESS_PAGE_URL = '/payment-success';
export const PAYMENT_FAILED_PAGE_URL = '/payment-failed';
export const CHECKOUT_LOADING_PAGE_URL = '/payment';
export const CHECKOUT_SUCCESS_PAGE_URL = '/checkout-success';
export const UNAVAILABLE_LOCATION_PAGE_URL = '/unavailable-location';
export const COUNTRY_RESTRICTION_PAGE_URL = '/country-restriction';
