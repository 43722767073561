// COMMON (E.S: Common)
export const USER_INFO_API = 'cloud/api/get-user';
export const USER_SETTING = 'cloud/v4/setting/system';

export const CREDIT_VOUCHER_API = 'cloud/api/v2/credit-vouchers';
export const CREDIT_BALANCE_API = 'cloud/api/v2/credits/balance';
export const PAYMENYWALL_WIDGET_API = 'cloud/api/v2/paymentwalls/widget';
export const PAYMENTWALL_CHECK_API = 'cloud/api/v2/paymentwalls/check';

export const PLAN_SUBSCRIPTION_UPGRADE_API = 'account/workspace-plan-subscriptions/upgrade';
export const ADD_SEATS_API = 'account/workspace-plan-subscriptions/seats/buy';
